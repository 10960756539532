<template>
  <v-dialog v-model="showRaceParticipants" max-width="1000px" persistent @input="close">
    <v-card class="participants-popup">
      <v-card-text class="pa-0">
        <div class="row header-row d-flex align-center">
          <div class="col-md-4">
            <div class="header">Participants<br>
            </div>
          </div>
          <div class="col-md-4">
            <div class="date-time">{{ date | dateformat}} | {{ start_time | timeFormat}} - {{ end_time |timeFormat}}<br>
            </div>
          </div>
          <div class="col-md-3">
            <div class="text-search">
              <v-text-field
                  v-model="search"
                  class="q-text-field shadow-0"
                  clearable
                  dense
                  hide-details="auto"
                  label="Search Participants"
                  outlined
                  style="width: 200px"
              ></v-text-field>
            </div>
          </div>
          <div class="col-md-1">
            <v-btn @click="raceSetup" icon>
              <v-icon>mdi-wrench-cog-outline</v-icon>
            </v-btn>
          </div>
        </div>
        <v-divider></v-divider>
        <v-row class="participants-area">
          <v-col v-if="participants.length > 0" class="p-0" cols="12" md="12" subheader>
            <div class="table-responsive">
              <table class="table border-collapse ">
                <thead >
                <tr class="opacity-70 tr-neon tr-rounded ">
                  <th v-if="race_started || race_completed ">
                    <div class="">Position</div>
                  </th>
                  <th>
                    <div class="">Name</div>
                  </th>
                  <th>
                    <div class="">Kart</div>
                  </th>
                  <th v-if="race_started || race_completed ">
                    <div class="">Best Lap</div>
                  </th>
                  <th v-if="race_started || race_completed ">
                    <div class="">Total time</div>
                  </th>
                  <th>
                    <div class="">Actions</div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="participant in filterParticipants"
                    :key="participant.id"
                >
                  <td v-if="race_started || race_completed ">
                    <div class="position-badge">
                      <span>{{ 1 | ordinal }}</span>
                    </div>
                  </td>
                  <td>
                    <v-list-item-avatar :height="48" :width="48" class="line ml-0" rounded="2">
                      <view-image
                          :contain="false"
                          :image="participant.profile_image"
                          defaultImage="user"
                      ></view-image>
                    </v-list-item-avatar>
                    <span class="text-truncate name-text" style="cursor: pointer"
                          @click="showUserModel(participant.customer_id)">{{ participant.name }}</span>
                  </td>
                  <td>
                    <div class="text-underline pointer"  @click="participantKart(participant)">
                      <strong v-if="participant.vehicle_id">{{ participant.vehicle_name}}</strong>
                      <span v-else> Click to Assign</span>
                    </div>
                  </td>
                  <td v-if="race_started || race_completed ">
                      <span>8 min 59 sec</span>
                  </td>
                  <td v-if="race_started || race_completed ">
                      <span>11 min 12 sec</span>
                  </td>
                  <td class="text-center">
                    <div class="d-flex align-center gap-x-2 justify-center">
                      <v-btn
                          v-show="race_completed"
                          class="btn-checkin svg-stroke-neon"
                          text
                          @click="openStats(participant)"
                      >
                        <span class="ml-1">Stats</span>
                      </v-btn>

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="text_capitalize btn-dot svg-stroke-primary"
                            outlined
                            v-bind="attrs"
                            v-on="on"

                        >
                          <EditBtnIcon/>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-if="participant.alert_notes != null && participant.alert_notes != ''"
                                     @click="openBooking(participant)">
                          <v-list-item-title class="fontsize-12">Alert</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            v-if="is_split_payment == 0 || (participant.so_parent_order_id != null && participant.order_id == participant.so_parent_order_id)"
                            @click="openBooking(participant)">
                          <v-list-item-title class="fontsize-12">View Booking</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="participant.check_in_time === null && participant.check_out_time == null"
                            @click="checkInAndOut(participant.group_customer_id)">
                          <v-list-item-title class="fontsize-12">Check In</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="participant.check_in_time != null && participant.check_out_time == null"
                            @click="checkInAndOut(participant.group_customer_id)">
                          <v-list-item-title class="fontsize-12">Check Out</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="participant.check_in_and_out === 'OUT'"
                            @click="voidCheckIn(participant.group_customer_id)">
                          <v-list-item-title class="fontsize-12">Void Check in</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="participant.status_id == 5"
                            @click="getOrderDetails(participant)">
                          <v-list-item-title class="fontsize-12">Invoice</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-else
                            @click="getOrderDetails(participant)">
                          <v-list-item-title class="fontsize-12">Receipt</v-list-item-title>
                        </v-list-item>
                        <!--                                                    <v-list-item  v-if="participant.order_customer_id == participant.customer_id" @click="getOrderDetails(participant)">-->
                        <!--                                                        <v-list-item-title  class="fontsize-12">View Transaction</v-list-item-title>-->
                        <!--                                                    </v-list-item>-->
                      </v-list>
                    </v-menu>

                    </div>
                  </td>
                </tr>
                </tbody>

              </table>
            </div>
          </v-col>
          <v-col v-else class="p-0" cols="12" md="12">
          <div class="d-flex justify-center align-center" style="height: 200px;"> No Participants</div>
        </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="bg-white w-full border-top" style="position: sticky; bottom: 0;">
        <v-spacer></v-spacer>
        <v-btn class="ma-2" text @click="close()">Close</v-btn>
        <v-btn v-if="participants.length > 0 && !race_started && !race_completed" class="ma-2 white--text blue-color btn btn-print"
               @click="startRace">
          Start Race
        </v-btn>
        <v-btn v-if="participants.length > 0 && race_started && !race_completed" class="ma-2 white--text blue-color btn btn-print"
               @click="completeRace">
          Finish Race
        </v-btn>
        <v-btn v-if="participants.length > 0 && race_started && race_completed" class="ma-2 white--text blue-color btn btn-print"
               @click="openLeaderboard">
          Leaderboard
        </v-btn>
      </v-card-actions>
    </v-card>
    <AssignKartModal
        :assignKartModal="assignKartModal"
        :participant="assignedKartParticipant"
        :vehicle="assignedKart"
        @close="closeAssignKartModal"
    />
    <race-setup-modal
        :participant="assignedKartParticipant"
        :raceSetupModal="raceSetupModal"
        @close="closeRaceSetupModal"
    />
    <StatsModal :dialog="statsDialog" @close="closeStats" />
    <order-details
        :id="orderId"
        :ids="orderIds"
        :invId="invoiceModel.invoiceId"
        :log="false"
        @close="(orderId = null), (orderIds = null), openParticipants()"
    ></order-details>
    <customer-model
        :isPermissionCheck="
            checkWritePermission($modules.clients.customers.slug)
          "
        v-bind="userModel"
        @close="userModel.userID = null"
    />

  </v-dialog>
</template>
<script>
import EditBtnIcon from "@/assets/images/misc/more-horizontal-square.svg";
// import InvoiceIcon from "@/assets/images/misc/invoice.svg";
// import LocationIcon from "@/assets/images/misc/location-icon.svg";
import OrderDetails from "@/components/Order/OrderDetails.vue";
import CustomerModel from "@/views/Clients/Customer/CustomerModel.vue";
import StatsModal from "@/views/Schedule/Facility/StatsModal.vue";
import AssignKartModal from "@/components/Schedule/Facility/AssignKartModal.vue";
import RaceSetupModal from "@/components/Schedule/Facility/raceSetupModal.vue";
// import PrintIcon from "@/assets/images/misc/print-icon.svg";

export default {
  name: "RaceParticipants",
  components: {
    RaceSetupModal,
    AssignKartModal,
    StatsModal,
    CustomerModel,
    OrderDetails,
    /*PrintIcon, LocationIcon, InvoiceIcon,*/ EditBtnIcon},
  props: {
    start_time: {type: String},
    showRaceParticipants: {type: Boolean, default: false},
    end_time: {type: String},
    date: {type: String},
    facility_id: {type: Number},
    refresh: {type: Boolean},
    venue_service_id: {type: Number},
    is_split_payment: {type: Number, default: 0},
    isTotalParticipantsShow: {type: Boolean, default: false},
  },
  watch: {
    showRaceParticipants(val) {
      if (val == true) {
        this.openParticipants();
      }
    },
    refresh() {
      this.openParticipants();
    },
  },
  computed: {
    filterParticipants() {
      const search = this.search;
      return search === "" || search == null ? this.participants : this.participants.filter((participant) => participant.name.toLowerCase().includes(search.toLowerCase()));
    },
  },
  data() {
    return {
      statsDialog:false,
      race_started:false,
      race_completed:false,
      participants: [],
      orderId: null,
      orderIds: null,
      search: null,
      userModel: {userID: null, type: "details"},
      invoiceModel: {
        invoiceId: null,
        invoiceIds: [],
        type: "details",
        orderIds: [],
      },
      assignedKart:null,
      assignedKartParticipant:null,
      assignKartModal:false,
    };
  },
  methods: {
    raceSetup(){
      this.assignedKartParticipant = this.participants[0];
      this.raceSetupModal = true;
    },
    participantKart(participant) {
      this.assignedKart = participant.vehicle_id;
      this.assignedKartParticipant = participant;
      this.assignKartModal = true;
    },
    closeAssignKartModal(){
      this.assignedKart = null;
      this.assignedKartParticipant = null;
      this.assignKartModal = false;
      this.openParticipants();
    },
    closeRaceSetupModal(){
      this.assignedKartParticipant = null;
      this.raceSetupModal = false;
      this.openParticipants();
    },
    closeStats(){
      this.statsDialog = false;
    },
    startRace(){
      this.race_started = 1
    },
    completeRace(){
      this.race_completed = 1
    },
    openStats(participant){
      console.log(participant);
      this.statsDialog = true;
    },
    openLeaderboard(){
      console.log('leaderboard');
    },
    openParticipants() {
      if (!this.facility_id) {
        return;
      }
      this.showLoader("Loading");
      this.totalCheckIn = 0;
      this.totalCheckOut = 0;
      this.payer = null;
      this.payerCustomerList = [];
      let url = "participants";
      if (this.is_split_payment == 1) {
        url = "split-participants";
      }
      if (this.isTotalParticipantsShow) {
        url = `${url}?facility_id=${this.facility_id}&date=${this.date}&venue_service_id=${this.venue_service_id}&is_split_payment=${this.is_split_payment}`;
      } else {
        url = `${url}?facility_id=${this.facility_id}&start_time=${this.start_time}&end_time=${this.end_time === "00:00:00" ? "23:59:00" : this.end_time}&date=${this.date}&venue_service_id=${this.venue_service_id}&is_split_payment=${this.is_split_payment}`;
      }
      this.$http.get(`venues/facilities/bookings/golf/${url}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              this.participants = data;
              if (this.isTotalParticipantsShow) {
                this.participants.forEach((el) => {
                  if (el.check_in_and_out == "OUT" || el.check_in_and_out == "GONE") {
                    this.totalCheckIn++;
                  }
                  if (el.check_in_and_out == "GONE") {
                    this.totalCheckOut++;
                  }
                });
              }
              this.hideLoader();
            }
          });
    },
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    close() {
      this.race_started = 0;
      this.race_completed = 0;
      this.invoiceModel.invoiceId = null;
      this.$emit("close");
    },
    openBooking(data) {
      data.status = data.status_id == 5 ? "unpaid" : "paid";
      data.is_split_payment = this.is_split_payment;
      this.$emit("open-capacity-booking", data);
    },
    closeOrderPaymentDialog() {
      this.enableMultiOrderPay = false;
      this.openParticipants();
    },
    getOrderDetails(participant) {
      let parentOrderIds = [];
      if (participant.parent_orders.length) {
        parentOrderIds.push(participant.order_id);
        participant.parent_orders.map((item) => {
          parentOrderIds.push(item.id);
        });
        this.orderIds = parentOrderIds;
      } else {
        this.orderIds = null;
      }
      this.orderId = participant.order_id;
    },
    voidCheckIn(id) {
      this.showLoader("Loading");
      this.$http
          .get(`venues/facilities/bookings/void-check-in/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.openParticipants();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    checkInAndOut(id) {
      this.showLoader("Loading");
      this.$http
          .get(`venues/facilities/bookings/check-in-and-out/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.openParticipants();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
  }

}
</script>

<style lang="scss" scoped>
.parent-list-items:not(:first-child) {
  border-top: 1px solid #e5e5e5;
}

.parent-list-items.child-row {
  border-top: 0;
  padding-left: 50px;
  position: relative;
}

.fontsize-12 {
  font-size: 12px;
}

.participants-popup .header-row {
  padding-top: 10px;
  margin: 5px;
}

.header, span.sum-price {
  letter-spacing: 0.48px;
  color: var(--dark-blue, #112A46);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

span.sum-price {
  font-size: 14px;
}

.text-search {
  width: 100%;
  display: flex;
}

.participants-area {
  padding: 10px 0px;
  margin: 0px 20px;
}

.parent-list-items {
  color: #112A46;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.btn-dot.v-btn {
  min-width: 24px !important;
  height: 30px !important;
  flex-shrink: 0;
  padding: 0 !important;
}

button.btn-checkin {
  color: #4FAEAF !important;
  font-size: 10px !important;
  border: 0.75px solid #4FAEAF;
  background: rgba(79, 174, 175, 0.10);
  height: 30px !important;
  flex-shrink: 0;
}

button.btn-checkout {
  height: 30px !important;
  flex-shrink: 0;
  color: #011627 !important;
  font-size: 10px !important;
  border: 0.75px solid #011627;
  background: rgba(1, 22, 39, 0.10);
}

button.btn-void-checkin {
  color: #E50000 !important;
  font-size: 10px !important;
  height: 30px !important;
  flex-shrink: 0;
  border: 0.75px solid #E50000;
  background: rgba(229, 0, 0, 0.10);
}

button.btn-print, button.btn-void-checkin, button.btn-checkout, button.btn-checkin, button.btn-invoice {
  min-width: 100px;
  max-width: 150px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 3px;
}

button.btn-void-checkin {
  width: 120px;
}


.modal-buttons {
  border-top: 1px inset;
}

span.cdisplayBlock {
  display: block;
  padding-top: 5px;
}

span.displayBlock {
  display: block;
}

span.checkinout {
  font-size: 12px;
  padding: 10px 10px 0 0;
}
.date-time{
  color: black;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  letter-spacing: 0.03em;
  text-align: left;
}

.name-text{
  color: #112A46;
}
.position-badge {
  display: flex;                   /* Use flexbox for centering */
  justify-content: center;         /* Center content horizontally */
  align-items: center;             /* Center content vertically */
  width: 50px;                     /* Set the width of the circle */
  height: 50px;                    /* Set the height of the circle */
  background-color: #4AB1B1;       /* Background color similar to the image */
  color: white;                    /* Text color */
  border-radius: 50%;              /* Make it a circle */
  border: 1.5px solid #0F2A4D;       /* Border color and thickness */
  font-size: 16px;                 /* Adjust font size as needed */
  font-weight: bold;               /* Make text bold */
  box-shadow: 0 0 5px rgba(0,0,0,0.2); /* Optional: Adds slight shadow */
}

.position-badge span {
  display: block;
}

</style>